.transaction-details-modal{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.transaction-details-container{
    position:fixed;
    height: auto;
    width: 100%;
    top:50%;
    /*bottom: 50%*/
}

/*position: fixed;*/
/*top: 0;*/
/*left: 0;*/
/*width:100%;*/
/*height: 100%;*/
/*background: rgba(0, 0, 0, 0.6);*/
