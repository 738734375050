/*body, html {*/
/*    height: 100%;*/
/*    background-color: #272727;*/
/*}*/

.container{
    height: 100%;
    background-color: #525252
}

.search-container button {
    float: right;
    margin-right: 16px;
    background: white;
    font-size: 17px;
    border: none;
    cursor: pointer;
    height: 40px
}
.search-container {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    margin-top: 12px;
    background-color: white;
}

.search-bar {
    border-radius: 0;
    border-right-width: 0;
    border: none;
    background-color: white;
    padding-left: 19px;
    color: #272727
}

/*::placeholder{*/
/*    color: rgba(82, 82, 82, 0.5);*/
/*}*/

.scroll {
    overflow: auto;
    white-space: nowrap;
}
