.item-container{
    border-bottom: 1px solid #64A5E8;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-right: 24px;
    margin-left: 24px
}
.store-name {
    color: white;
    font-weight: bold;
    position: relative;
    bottom: 5%;
    text-align: left;
    font-size: 13px;
}
.distance {
    color: white;
    position: relative;
    bottom: 20%;
    font-weight: normal;
    text-align: left;
    font-size: 13px;
}


