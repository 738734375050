body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Gotham',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #231F20;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: .5px solid white;
  border-radius: 8px;
  width: 95%;
  margin: auto;
  color: white;
  background-color: #231F20;
  height: 30px
}
select {
  border: .5px solid white;
  border-radius: 8px;
  color: white;
  width: 100%;
  height: 47px;
  background-color:transparent;
  padding-right: 8px
  /*border-bottom-color: white;*/
  /*border-left-color: transparent;*/
  /*border-right-color: transparent;*/
  /*border-top-color: transparent;*/
  /*border-bottom-width: 1px;*/
}

::selection {
  color: white;
}

label {
  font-family: 'Gotham', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: white;
  float: left;
  margin-bottom: 2px;
  margin-top: 12px;
}

::placeholder{
  color: white;
  opacity: 0.5;
  font-size: 11px;
}

h4 {
  color: white
}

h2 {
  color: #466684
}

ul.contentful {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-right: 40px
}

h4.button-text {
  color:#64A5E8;
  text-align: center;
}

h4.alert-button-text{
  color: white;
  text-align: center;
}

image{
  object-fit: contain;
}

p.gender {
  font-family: 'Gotham', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color:white;
  float: left;
  margin-bottom: 8px;
  margin-top: 18px;
}

p.gender-label {
  font-family: 'Gotham', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
}

p.pin-caption {
  font-family: 'Gotham', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: white;
}

p.forgot-pin-text {
  font-family: 'Gotham', sans-serif;;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #466684;
}

p.landing-caption {
 color: white;
}

div.carousel-inner{
  display: flex;
  width: 100%;
}

div.carousel-indicators{
  display: flex;
}

div.radio-component {
  /*float: left;*/
  /*display: flex;*/
  margin-left: 0;
}

div.walkthrough-buttons{
  display: flex;
}

img.radio-icon {
  float: left;
}

button.general {
  height: 48px;
  border-radius: 100px;
  background-color: white;
  width: 216px;
  border-width: 0;
}

button.alert-button {
  height: 48px;
  border-radius: 100px;
  background-color: #231F20;
  width: 216px;
  border-width: 0;
}

button.forgot-pin-button{
  background-color: transparent;
  border-color: transparent;
}

img.logo{
  align-items: center;
  justify-content: center;
}

div.row {
  display: flex;
  width: 100%
}

*:focus {
  outline: none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #231F20 inset !important;
  -webkit-text-fill-color: white !important;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0 solid transparent;
  border-bottom-color: white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 1000px #231F20 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="date"] ::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.react-datepicker-wrapper {
  /*height: 105px;*/
  /*width: 97%;*/

}
.react-datepicker__input-container {
  /*margin: 24px;*/
  /*background-color: red;*/
  /*height: 45px;*/
  /*width: inherit;*/
}
.react-datepicker__input-container input {
  /*display: block;*/
  width: 97%;
  /*border-bottom-color: white;*/
  /*border-bottom-width: 0.5px;*/
  height: 44px;
  /*margin-right: 29px;*/
  /*padding-bottom: 16px;*/
  padding-right: 8px;
  /*padding-left: 4px;*/
  /*margin-right: 8px*/
}



