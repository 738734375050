.details-container {
    position:fixed;
    height: auto;
    width: 100%;
    top:50%;
    bottom: 0
}
.store-title {
    text-align: center;
    border-bottom-color: transparent;
}

.store-title p {
    font-weight: bold;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 10%;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    overflow-y: scroll;
}
