.close {
    width: 10%
}
.header-container {
    justify-content: space-between;
}
.title {
    height: 0
}

