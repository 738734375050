.container {
    display: inline-flex;
    justify-content: center;
    height: 32px;
    margin-bottom: 21px;
}

.toggle-item {
    width: 77px;
    height: 32px;
    border: 1px solid white;
}

.text{
    position: relative;
    bottom: 30%;
    font-weight: bold;
}

