.prefix input {
    border-radius: 0 4px 4px 0;
}

.prefix .input-group-addon {
    border-right: 0;
    border-radius: 4px 0 0 4px;
}

.input-group-addon.prefix {
    border-radius: 4px 0 0 4px;
    border-right: 0;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group-addon {
    padding-top: 12px;
    padding-right: 6px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    background-color: #231F20;
    border: 0.5px solid  white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right-color: transparent;
    white-space: nowrap;
    display: table-cell;
    color: white;
    width: 20%;
}
.mobile {
    padding-left: 8px;
    padding-right: 16px;
    border-bottom: .5px solid  white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    color: white;
    width: 100%;
    height: 40px;
    /*border-left-color: white;*/
    /*background-color: blue;*/
}

.mobile-profile {
    padding-left: 8px;
    padding-right: 16px;
    border-bottom: .5px solid  white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: .5px solid  white;
    color: white;
    width: 100%;
    height: 40px;
}



