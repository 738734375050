.container {
    /*justify-content: center;*/
    /*align-self: center;*/
    background-color:blue;
    /*border-color: black;*/
    /*border-width: 1px;*/
    width: 100%;
    margin-bottom: 0
}

.title {
    /*padding-bottom: 20px;*/
    align-items: center;
    justify-content: center;
    background-color: red;
    margin-top: -16px
}


.title-container {
    /*width: auto;*/
    /*height: 40px;*/
    /*padding-top: 16px;*/
    margin-left: auto;
    margin-right: auto;
    width: 80%
}

.article {
    background-color: transparent;
    border-color: transparent;
}
