
/*body, html {*/
/*    height: 100%*/
/*}*/

.background {
    background-image: url("../../assets/images/homeBackground.png");
    height: auto;
    background-position: center;
    background-repeat: repeat-y;
    background-size: cover;
    background-attachment: fixed
}

.user-greeting {
    color: white;
}

.user-balance {
    color: white;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
}

.user-balance-caption {
    color: white;
    font-size: 13px;
    font-weight: 900
}

.button-container {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 0;
}

.user-info-container {
    background-color:  #272727;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 16px;
    padding-bottom: 24px;
    border-radius: 8px;
    mix-blend-mode: screen;
    margin-top: 80px;
    height: 288px
}

.bonus-rewards-caption {
    background-color:  #272727;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    mix-blend-mode: screen;
    border-radius: 8px;
    height: 86px;
    padding-top: 16px
}

.list {
    /*justify-content: center;*/
    /*align-items: center;*/
    /*background-color: blue;*/
    margin-top: 30px
}

.img-wrap {
    position: relative;
    display: inline-block;
}

.pointer {
    transform-origin: 76px center;
}

@keyframes spin6 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}


@keyframes spin5 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(150deg);
    }
}

@keyframes spin4 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(120deg);
    }
}

@keyframes spin3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(60deg);
    }
}

@keyframes spin1 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(30deg);
    }
}

@keyframes spin0 {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg)
    }
}


